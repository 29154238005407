import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import '../assets/scss/argon.scss';
import '../assets/scss/font-awesome.scss';
import Header from '../components/Header';
import './layout.scss';
import styled from 'styled-components';
import Footer from '../components/Footer';

interface LayoutProps {
  className?: string;
  keywords?: string;
  title?: string;
  fullScreen?: boolean;
  description?: string;
  ogImage?: any;
  children: JSX.Element[] | JSX.Element;
}

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description?: string;
      siteUrl: string;
      image?: string;
      keywords?: string;
    };
  };
}

const CookieBannerPosition = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  z-index: 100;
`;

class Layout extends React.Component<LayoutProps, {}> {
  public render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
                siteUrl
                description
                keywords
              }
            }
          }
        `}
        render={(data: StaticQueryProps) => {
          const { siteMetadata } = data.site;
          const { children } = this.props;
          return (
            <>
              <Helmet
                title={this.props.title ? this.props.title : siteMetadata.title}
                meta={[
                  {
                    name: 'description',
                    content: this.props.description
                      ? this.props.description
                      : siteMetadata.description,
                  },
                  {
                    name: 'keywords',
                    content: this.props.keywords
                      ? this.props.keywords
                      : siteMetadata.keywords,
                  },
                  {
                    property: 'og:title',
                    content: this.props.title
                      ? this.props.title
                      : siteMetadata.title,
                  },
                  {
                    property: 'og:description',
                    content: this.props.description
                      ? this.props.description
                      : siteMetadata.description,
                  },
                  {
                    property: 'og:type',
                    content: 'website',
                  },
                  {
                    name: 'twitter:card',
                    content: 'summary',
                  },
                  {
                    name: 'twitter:site',
                    content: '@droidops4dev',
                  },
                  {
                    name: 'twitter:creator',
                    content: '@droidops4dev',
                  },
                  {
                    name: 'twitter:title',
                    content: this.props.title
                      ? this.props.title
                      : siteMetadata.title,
                  },
                  {
                    name: 'twitter:image',
                    content: this.props.ogImage,
                  },
                  {
                    name: 'twitter:description',
                    content: this.props.description
                      ? this.props.description
                      : siteMetadata.description,
                  },
                  {
                    property: 'og:image',
                    content: `${siteMetadata.siteUrl}${this.props.ogImage}`,
                  },
                ]}
              >
                <script type="application/ld+json">
                  {JSON.stringify({
                    '@context': 'http://schema.org/',
                    '@type': 'WebSite',
                    name: 'DroidOps',
                    url: 'https://home.droidops.org',
                    alternateName: this.props.title
                      ? this.props.title
                      : siteMetadata.title,
                  })}
                </script>
              </Helmet>
              {!this.props.fullScreen && (
                <Header
                  siteTitle={
                    this.props.title ? this.props.title : siteMetadata.title
                  }
                />
              )}
              <div className={this.props.className}>
                {children}
                {!this.props.fullScreen && <Footer />}
              </div>
            </>
          );
        }}
      />
    );
  }
}

export default Layout;
