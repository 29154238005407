import styled from 'styled-components';
import {
  SectionParent,
  BlueButton,
} from '../../../components/StyledComponents';
import React from 'react';
// import YourAppYourDevicesMobile from '../../../assets/v2/homepage/Andi_heroimage_mobile.svg';
import { navigate, graphql, StaticQuery } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import droidOpsImage from '../../../assets/droidOps_tempLogo.svg'
import Image from 'gatsby-image';

const ImgItem = styled(Image)`
  width: 100%;
  margin-top: 6rem;
  margin-left: 2rem;
  @media (max-width: 768px) {
    margin-top: 0rem;
    margin-left: 0;
  }
`;

const ImgItemMobile = styled(LazyLoadImage)`
  display: none;
  @media (max-width: 520px) {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    display: unset;
  }
`;

const SectionOne = styled(SectionParent)`
  padding-top: 6rem !important;
  padding-bottom: 5rem !important;
  background-color: #f9fbfe;
  min-height: calc(100vh - 6rem);
  @media (max-width: 768px) {
    padding-top: 5rem !important;
  }
  @media (max-width: 520px) {
    padding-top: 5rem !important;
  }
  width: 100%;
  display: grid;
`;

const SectionItemContainer = styled.div`
  grid-template-columns: repeat(24, 1fr);
  display: grid;
`;

export const Title = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #151828;
  @media (max-width: 1024px) {
    font-size: 36px;
  }
  @media (max-width: 520px) {
    font-size: 28px;
  }
`;
export const SectionOneTitle = styled(Title)`
  color: #151828;
  @media (max-width: 768px) {
    text-align: center;
  }
  @media (max-width: 520px) {
    text-align: center;
  }
`;

const GridLeft = styled.div`
  grid-column: span 11;
  /* padding-left: 3rem; */
  margin-top: 3rem;
  align-content: center;
  display: grid;
  @media (max-width: 768px) {
    grid-column: span 24;
    padding-right: 0rem;
    margin-top: 0rem;
    margin-bottom: 3rem;
    padding-left: 0rem;
    min-height: 15rem;
  }
`;
const GridRight = styled.div`
  grid-column: span 13;
  display: grid;
  @media (max-width: 768px) {
    grid-column: span 24;
  }
`;

const HeroSubTitle = styled.div`
  padding-top: 2rem;
  font-family: 'Roboto', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  max-width: 28em;
  color: #626679;
  @media (max-width: 768px) {
    text-align: center;
  }

  @media (max-width: 520px) {
    text-align: center;
    padding-top: 0.5rem;
    margin-bottom: -1rem;
  }
`;
const BlueButtonWrapper = styled.div`
  margin-left: -10px;
  margin-top: 2rem;
  button {
    border-radius: 8rem;
  }
  @media (max-width: 768px) {
    text-align: center;
    margin-top: 2rem;
  }
  @media (max-width: 520px) {
    justify-self: center;
  }
`;

const BottomText = styled.div`
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  display: grid;
  width: 65%;
  justify-self: center;
  padding-top: 5rem;
  @media (max-width: 1350px) and (min-width: 1050px) {
    width: 60%;
  }
  @media (max-width: 800px) {
    padding: 0;
    width: 100%;
    padding-top: 6rem;
  }
  @media (max-width: 520px) {
    font-size: 18px;
  }
`;

const StyledImage = styled.img`
  max-width: 220px;
  @media (max-width: 768px) {
    justify-self: center;
    max-width: 200px;
  }
`

export default () => (
  <div style={{ zIndex: 1, position: 'relative', background: '#f9fbfe' }}>
    <SectionOne>
      <SectionItemContainer>
        <GridLeft>
          <StyledImage src={droidOpsImage}/>
          <SectionOneTitle>
          Pitstop for your next Embedded Android IoT Projects!
          </SectionOneTitle>
          <HeroSubTitle style={{ paddingTop: '1rem' }}>
          DroidOps is the world’s first developer community dedicated exclusively to single-purpose Android devices and apps.
          </HeroSubTitle>
          <BlueButtonWrapper>
            <a href="https://airtable.com/shrsdJJrJYzfk9k8f">
            <BlueButton
              id="kiosk__signup_for_free_2"
            >
              Join community
            </BlueButton>
            </a>
          </BlueButtonWrapper>
        </GridLeft>
        <GridRight>
          <StaticQuery
            query={graphql`
              query {
                file(relativePath: { eq: "homepage_new.png" }) {
                  childImageSharp {
                    fluid(
                      quality: 100
                      traceSVG: { color: "#F46445", threshold: 75 }
                    ) {
                      aspectRatio
                      src
                      srcSet
                      sizes
                      srcWebp
                      srcSetWebp
                      tracedSVG
                    }
                  }
                }
              }
            `}
            render={(data: any) => (
              <div style={{ alignSelf: 'center' }}>
                <ImgItem
                  fluid={data.file.childImageSharp.fluid}
                  alt="Think Beyond Managing Devices"
                  loading="lazy"
                />
              </div>
            )}
          />
        </GridRight>
      </SectionItemContainer>
    </SectionOne>
  </div>
);
