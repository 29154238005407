import React from 'react';
import styled from 'styled-components';
import { SectionParent } from './StyledComponents';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import linkedinIcon from '../assets/linkedIn.svg';
import twitterIcon from '../assets/twitter.svg';
import fbIcon from '../assets/fb.svg';
import { Link } from 'gatsby';
const FooterSection = styled.div`
  grid-template-columns: repeat(12, 1fr);
  background-color: #151828;
`;

const FooterSectionTitle = styled.div`
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #2f2f2f;
  margin-bottom: 1rem;
  letter-spacing: normal;
`;

const TwoColumn = styled.div`
  padding-top: 1rem;
  grid-column: span 2;
  @media (max-width: 960px) {
    padding-top: 0rem;
    padding-bottom: 1rem;
  }

  @media (max-width: 520px) {
    grid-column: span 3;
    justify-self: left;
    text-align: left;
  }
`;

const CopyRightText = styled.span`
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  align-self: center;
  justify-self: left;
`;

const FooterLogos = styled(LazyLoadImage)`
  margin-left: 0rem;
  cursor: pointer;
  opacity: 1;
  transition: 300ms ease-in;
  @media (min-width: 960px) {
    float: left;
  }
  :hover {
    opacity: 0.8;
  }
`;

const FooterSocialIconsGroup = styled.div`
  display: grid;
  grid-template-columns: 50px 50px 50px;
  justify-content: right;
`;

const FooterSectionBottom = styled(SectionParent)`
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  display: grid;
  border-top: solid 1px rgba(116, 124, 157, 0.11);
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 520px) {
    margin-bottom: 1rem;
    justify-items: center;
    grid-gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Description = styled.div`
  font-family: 'Roboto', sans-serif;
  margin-top: 22px;
  font-size: 15px;
  padding-right: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.73;
  letter-spacing: normal;
  color: #54586b;
`;

const Address = styled(Description)`
  color: #54586b;
`;

const ColumnItem = styled(Link)`
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.4;
  letter-spacing: normal;
  color: #54586b;
`;
const ColumnItemA = styled.a`
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.4;
  letter-spacing: normal;
  color: #54586b;
`;
export default () => (
  <FooterSection id="footer-main">
    <FooterSectionBottom>
      <CopyRightText>
        © Copyright {new Date().getFullYear()} DroidOps - All Rights Reserved
      </CopyRightText>
      <FooterSocialIconsGroup>
        <a
          href="https://www.linkedin.com/company/droidops/"
          rel="noopener noreferrer"
          target="_blank"
          aria-label="linkedin"
          id="footer-main-linkedin"
        >
          <FooterLogos
            alt="linkedin"
            src={linkedinIcon}
            id="footer-main-linkedin-logo"
          />
        </a>
        <a
          href="https://twitter.com/droidops4dev"
          rel="noopener noreferrer"
          target="__blank"
          aria-label="Twitter"
          id="footer-main-twitter"
        >
          <FooterLogos
            alt="twitter"
            src={twitterIcon}
            id="footer-main-twitter-logo"
          />
        </a>
        <a
          href="https://www.facebook.com/droidops/"
          rel="noopener noreferrer"
          target="_blank"
          aria-label="facebook"
          id="footer-main-facebook"
        >
          <FooterLogos
            alt="facebook"
            src={fbIcon}
            id="footer-main-facebook-logo"
          />
        </a>
      </FooterSocialIconsGroup>
    </FooterSectionBottom>
  </FooterSection>
);
