import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
// import { BlueButton, H3, B3 } from '../StyledComponents';
import './Header.scss';
import droidOpsLogo from '../../assets/droidOps_tempLogo.svg';
// import Dev from '../../assets/group-41.svg';
// import Commercial from '../../assets/commercial_hardware.svg';
// import Custom from '../../assets/custom_hardware.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
// import Enterprise from '../../assets/group-33.svg';
// import { navigate } from '@reach/router';

interface HeaderProps {
  siteTitle?: string;
  location?: any;
}

// const BlueButtonWrapper = styled(BlueButton)`
//   @media (max-width: 1280px) {
//     padding: 0rem 2rem;
//   }
//   @media (max-width: 980px) {
//     padding: 0rem 3rem;
//   }
// `;
// const HeaderTitle = styled(B3)`
//   font-weight: 400;
//   padding-left: 5px;
//   font-size: 14px;
//   margin-top: 20px;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: #2f2f2f;
//   transition: color 0.35s cubic-bezier(0.4, 0, 0.2, 1);
//   :hover {
//     color: #F46445;
//   }
// `;
// const HeaderItem = styled.div`
//   display: grid;
//   padding: 0rem !important;
//   background-color: #f9fbfe;
//   grid-template-columns: repeat(2, 1fr);

//   @media (max-width: 768px) {
//     grid-template-columns: auto;
//   }
// `;

// const LoginSpan = styled.span`
//   font-family: 'Roboto', sans-serif;
//   @media (max-width: 1280px) {
//     margin-left: 2rem !important;
//   }
//   @media (max-width: 1150px) {
//     margin-left: 0.25rem !important;
//   }
//   @media (max-width: 768px) {
//     margin-left: 0.25rem !important;
//   }
// `;

// const HeaderDescription = styled(B3)`
//   font-size: 12px;
//   padding-left: 5px;
//   margin-top: 5px;
//   color: #4a5275a6;
// `;

// const SolutionFlex = styled.div`
//   display: flex;
//   align-items: center;
//   height: 42px;
// `;

// const SolutionCategoryContainer = styled.div`
//   margin-bottom: 20px;

//   @media (max-width: 990px) {
//     margin-top: -1rem;
//     margin-left: -1rem;
//   }
//   @media (max-width: 770px) {
//     &:nth-of-type(2) {
//       margin-top: 2rem;
//       margin-bottom: 0;
//     }
//   }
// `;

// const SolutionCategoryTitle = styled(B3)`
//   margin-bottom: 12px;
//   font-size: 14px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: #2f2f2f;
// `;
// const SolutionHeaderTitle = styled(B3)`
//   height: 30px;
//   position: relative;
//   top: 50%;
//   transform: translateY(-50%);
//   padding-left: 7px;
//   font-size: 14px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: #2f2f2f;
// `;

const CustomerStories = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  align-self: center;
  font-style: normal;
  margin-left: -20px;
  justify-self: left;
  text-align: left;
  margin-top: 8px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;

  @media (max-width: 1600px) {
    display: none;
  }
  @media (max-width: 900px) {
    display: unset;
  }
  @media (max-width: 400px) {
    display: none;
  }
`;
class Header extends React.PureComponent<HeaderProps, {}> {
  state = {
    sticky: false,
    isPageLight: false,
    currentPage: '',
    isPageExternal: false,
    drawerOpen: false,
    expandItemMobile: '',
  };
  componentDidMount() {
    this.setState({ isPageLight: true });
    this.setState({ isPageExternal: false });
    const navbar = document.getElementById('navbar-main');
    this.setState({ currentPage: window.location.pathname.split('/')[1] });
    if (navbar) {
      const sticky = navbar.offsetTop + 1;
      document.onscroll = () => this.myFunction(sticky);
    }
  }

  myFunction = (sticky: any) => {
    if (window.pageYOffset > sticky) {
      this.setState({ sticky: true });
    } else {
      this.setState({ sticky: false });
    }
  };

  public render() {
    return (
      <header>
        <nav
          id="navbar-main"
          className={`navbar navbar-main navbar-expand-lg navbar-transparent ${
            (this.state.isPageLight ||
              (this.state.sticky && !this.state.drawerOpen)) &&
            !this.state.isPageExternal
              ? `navbar-light navbar-sticky`
              : `navbar-dark`
          }`}
          style={
            this.state.isPageExternal
              ? { backgroundColor: '#1b2341' }
              : this.state.sticky
              ? {
                  position: 'fixed',
                  backgroundColor: '#ffffff',
                  height: '10%',
                  transition:
                    'height 0.25s ease-in-out, background-color 0.25s ease-in-out',
                  boxShadow: '0 4px 9px 0 rgba(72,82,108,0.15)',
                }
              : {
                  transition:
                    'height 0.25s ease-in-out, background-color 0.s ease-in-out',
                  // height: '12%',
                }
          }
        >
          <div
            className="container header"
            style={{ paddingLeft: '0px', paddingRight: '0px' }}
          >
            {/* <Link
              className="navbar-brand mr-lg-2"
              aria-label="Esper Logo"
              title="Esper Logo"
              to="/"
            >
              {this.state.drawerOpen ? undefined : (this.state.isPageLight ||
                  this.state.sticky) &&
                !this.state.isPageExternal ? (
                <LazyLoadImage
                  src={EsperLogoBlue}
                  alt="Esper logo"
                  id="navbar-main-logo"
                  style={{
                    height: '44px',
                    width: '180px',
                    marginLeft: '-26px',
                    transition: '1s ease-in-out',
                  }}
                />
              ) : (
                <LazyLoadImage
                  src={EsperLogoWhite}
                  alt="Esper logo"
                  id="navbar-main-logo"
                  style={{
                    height: '44px',
                    width: '180px',
                    marginLeft: '-26px',
                    transition: '1s ease-in-out',
                  }}
                />
              )}
            </Link> */}

            {this.state.isPageExternal && (
              <CustomerStories>/ Customer Stories</CustomerStories>
            )}
            <button
              className={`navbar-toggler ${
                this.state.drawerOpen ? '' : 'collapsed'
              }`}
              type="button"
              data-toggle="collapse"
              data-target="#navbar_global"
              onClick={() => this.setState({ drawerOpen: true })}
              aria-controls="navbar_global"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={`navbar-collapse collapse ${
                this.state.drawerOpen ? 'show ' : ''
              }`}
              id="navbar_global"
            >
              <div className="navbar-collapse-header">
                <div className="row">
                  <div className="col-6 collapse-brand">
                    <Link aria-label="DroidOps Logo" title="DroidOps Logo" to="/">
                      <LazyLoadImage
                        src={droidOpsLogo}
                        id="navbar-main-logo"
                        alt="DroidOps logo"
                        style={{
                          height: '44px',
                          width: '180px',
                          marginLeft: '-26px',
                          transition: '1s ease-in-out',
                        }}
                      />
                    </Link>
                  </div>
                  <div className="col-6 collapse-close">
                    <button
                      type="button"
                      onClick={() => this.setState({ drawerOpen: false })}
                      className="navbar-toggler"
                      data-toggle="collapse"
                      data-target="#navbar_global"
                      aria-controls="navbar_global"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span />
                      <span />
                    </button>
                  </div>
                </div>
              </div>
              <ul className="navbar-nav navbar-nav-hover align-items-lg-center" />
              <ul className="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
                {/* <li className="nav-item">
                  <Link
                    className="nav-link "
                    to="/platform"
                    rel="preload"
                    data-toggle="tooltip"
                    title="Platform"
                  >
                    <span
                      style={
                        this.state.currentPage === 'platform'
                          ? { fontFamily: `Roboto`, fontWeight: 'bold' }
                          : { fontFamily: `Roboto` }
                      }
                      className="nav-link-inner--text"
                    >
                      Platform
                    </span>
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    to="#about_us"
                    rel="preload"
                    id="navbar-main-developer"
                    data-toggle="tooltip"
                    title="About us"
                  >
                    <span
                      style={
                        this.state.currentPage === 'developer'
                          ? { fontFamily: `Roboto`, fontWeight: 'bold' }
                          : { fontFamily: `Roboto` }
                      }
                      className="nav-link-inner--text"
                      id="navbar-main-developer-text"
                    >
                      About us
                    </span>
                  </Link>
                </li>

                {/* <li className="nav-item">
                  <Link
                    className="nav-link "
                    to="/login"
                    data-toggle="tooltip"
                    title="Login"
                  >
                    <LoginSpan
                      id="navbar-main-login-top-button"
                      className="nav-link-inner--text"
                    >
                      Login
                    </LoginSpan>
                  </Link>
                </li>
                <li className="nav-item d-none d-lg-block ml-lg-2">
                  <BlueButtonWrapper
                    onClick={() => {
                      navigate('/signup');
                    }}
                    id="navbar-main-signup-top-button"
                    style={{ fontWeight: 'bold', letterSpacing: '0.5px' }}
                  >
                    Sign up
                  </BlueButtonWrapper>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
