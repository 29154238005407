import styled from 'styled-components';
import {
  SectionParent,
  BlueButton,
} from '../../StyledComponents';
import React from 'react';
import { navigate } from 'gatsby';

const Section = styled(SectionParent)`
  background-repeat: no-repeat;
  background-color: #ffffff;
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
  @media (max-width: 520px) {
    padding-top: 2rem !important;
    /* padding-bottom: 5rem !important; */
  }
  width: 100%;
  display: grid;
  justify-items: center;
`;

const Heading = styled.div`
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  line-height: 1.21;
  color: #151828;

  text-align: center;
  padding-bottom: 2rem;
  @media (max-width: 520px) {
    font-size: 24px;
  }
`;

const SubHeading = styled.div`
  font-size: 21px;

  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #626679;
  margin: auto;
  max-width: 800px;
  line-height: 1.78;
  text-align: center;
  padding-bottom: 1rem;
`;

const BlueButtonNew = styled(BlueButton)`
  margin-top: 2rem;
`;

export default class AboutUs extends React.Component<any, any> {
  render() {
    return (
      <Section id="about_us">
        <Heading>About Us</Heading>
        <SubHeading>
          DroidOps is an initiative from Esper, a Seattle based high-growth startup. Esper is bringing DevOps to Android Device Fleet Management for Retail, Healthcare, Hospitality, Education and Logistics. We want to share our knowledge and expertise in android embedded devices with the rest of the world.
        <br /><br />
Android has become the most popular platform for IoT devices which require a user experience. The latest generation of connected devices is all run on Android, from smart bikes to mobile point-of-sale and restaurant kiosks.
<br /><br />
We want to enable the DroidOps members to build the next generation of Android-powered devices.
        </SubHeading>
        <a href="https://airtable.com/shrsdJJrJYzfk9k8f">
          <BlueButtonNew
            id="product_get_more_info_pricing"
          >
            Join Community
        </BlueButtonNew>
        </a>
      </Section>
    );
  }
}
