import styled from 'styled-components';

export const SectionParent = styled.section`
  padding: 0rem 14rem;
  @media (max-width: 1680px) and (min-width: 1440px) {
    padding: 0rem 10rem;
  }
  @media (max-width: 1440px) and (min-width: 768px) {
    padding: 0rem 5rem;
  }
  @media (max-width: 768px) {
    padding-top: 9px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media (max-width: 520px) {
    padding: 1rem;
  }
`;

export const Button = styled.button`
  height: 48px;
  padding: 0rem 3rem;
  border-radius: 2px;
  cursor: pointer;
  margin: 10px;
  /* text-transform: uppercase; */
  overflow: hidden;
  border-width: 0;
  outline: none;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 520px) {
    font-size: 16px;
    padding: 0rem 1rem;
    height: 2.5rem;
  }
  /* font-weight: 600; */
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-rendering: geometricprecision;
  letter-spacing: normal;
  transition: all 0.05s linear;
  :hover {
    box-shadow: 0 6px 10px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  :focus {
    box-shadow: 0 6px 10px rgba(50, 50, 93, 0.3), 0 1px 3px rgba(0, 0, 0, 0.08);
    outline: none;
  }
`;

export const BlueButton = styled(Button)`
  border-radius: 35px;
  background: linear-gradient(45deg, #F46445, #F46445);
`;
