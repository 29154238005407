import React from 'react';
import Layout from '../layout';
import AndiHero from '../assets/homepage_new.png';
import ProductSectionOne from '../components/Esper/Product/ProductSectionOne';
import AboutUs from '../components/Esper/Product/AboutUs';

class IndexPage extends React.PureComponent<{}, any> {
  public render() {
    return (
      <Layout
        ogImage={AndiHero}
        keywords={`Android DevOps, enterprise device management , Future of MDM solutions, android enterprise device management`}
        description={
          'DroidOps is the first platform built for Android enterprise device management and DevOps, so you can deploy devices, manage apps, and secure your fleet.'
        }
        title={'The Future of MDM is Here | Move to Android DevOps'}
        className="first-page"
      >
        <ProductSectionOne />
        <AboutUs />
      </Layout>
    );
  }
}

export default IndexPage;
